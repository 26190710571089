var deadline = new Date("November 29, 2024 23:59:59 +0100").getTime();
var x = setInterval(function() {
  var now = new Date().getTime();
  var t = deadline - now;
  var days = Math.floor(t / (1000 * 60 * 60 * 24));
  var hours = Math.floor((t%(1000 * 60 * 60 * 24))/(1000 * 60 * 60));
  var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((t % (1000 * 60)) / 1000);
  // Banner Top
  if(document.getElementById("countdown") !== null){
    document.getElementById("countdown").innerHTML = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
    if (days <= 0){
      document.getElementById("countdown").innerHTML = hours + "h " + minutes + "m " + seconds + "s ";
    }
    if (t < 0) {
      clearInterval(x);
      document.getElementById("countdown").innerHTML = "";
    }
  }
  // Princig
  if(document.getElementById("countdown-pricing") !== null){
    document.getElementById("countdown-pricing").innerHTML = "Termina en " + days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
    if (days <= 0){
      document.getElementById("countdown-pricing").innerHTML = "Termina en " + hours + "h " + minutes + "m " + seconds + "s ";
    }
    if (t < 0) {
      clearInterval(x);
      document.getElementById("countdown-pricing").innerHTML = "";
    }
  }
  // Subscription
  if(document.getElementById("countdown-subscription") !== null){
    document.getElementById("countdown-subscription").innerHTML = "Cerramos puertas en " + days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
    if (days <= 0){
      document.getElementById("countdown-subscription").innerHTML = "Cerramos puertas " + hours + "h " + minutes + "m " + seconds + "s ";
    }
    if (t < 0) {
      clearInterval(x);
      document.getElementById("countdown-subscription").innerHTML = "";
    }
  }
}, 1000);
